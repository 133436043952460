import moment from 'moment';
import { ReactNode } from 'react';

import { IMAGE_URL, PAGE_SIZE } from 'app/config';
import { EditingUser, User } from 'app/models';
import { FormCoordinate } from 'store/Form';

export const getImageURL = (imgSrc: string, size?: number | 'original') => {
  if (imgSrc && imgSrc.includes('https')) {
    return imgSrc;
  } else if (imgSrc) {
    return `${IMAGE_URL}/${size ? size : '320'}/${imgSrc}`;
  }
  return '';
};

export const getLastUpdateText = (
  updatedAt: string,
  user?: EditingUser | User
): string => {
  return (
    `最後更新於${moment(updatedAt).format('YYYY/MM/DD  HH:mm')}  ` +
    `${user ? user.userName + '編輯 ' : ' '}`
  );
};

export const getCreatedByText = (user?: EditingUser): string => {
  return `${user ? '由' + user.userName + '創建' : ' '}`;
};

export const getOffset = (pageSize: number = PAGE_SIZE) => {
  const searchParams = new URLSearchParams();
  return (parseInt(searchParams.get('page') || '1') - 1) * pageSize;
};

export const joinAddress = (
  district: string | null,
  street: string | null,
  building: string | null
) => {
  return (district ?? '') + (street ?? '') + (building ?? '');
};

export const joinDate = (startDate: string, endDate: string) => {
  return (startDate ?? '-/-/-') + ' - ' + (endDate ?? '-/-/-');
};

export const formatDigits = (number: number) => {
  const formatter = Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 20,
    useGrouping: false,
  });
  return formatter.format(number);
};

export const containsOnlyNumbers = (
  value: string,
  canNegative?: boolean,
  withoutComma?: boolean
) => {
  const regexWithoutComma = canNegative
    ? /^-?[0-9]+(\.[0-9]{0,2})?$/
    : /^[0-9]+(\.[0-9]{0,2})?$/;
  const regexWithComma = canNegative
    ? /^-?([0-9]|,)*(\.[0-9]{0,2})?$/
    : /^([0-9]|,)*(\.[0-9]{0,2})?$/;
  if (!withoutComma) {
    return regexWithoutComma.test(value) || regexWithComma.test(value);
  }
  return regexWithoutComma.test(value);
};

export const containsOnlyInteger = (
  value: string,
  unlimitedLength?: boolean
) => {
  const regex = /^[0-9]{0,3}$/;
  const unlimitedRegex = /^[0-9]{0,}$/;
  return unlimitedLength ? unlimitedRegex.test(value) : regex.test(value);
};

export const formatNumber = (
  value: string,
  zeroDigitsOnly?: boolean,
  withoutComma?: boolean
) => {
  if (!value) {
    return '';
  }
  let tempValue = value.replaceAll(',', '');
  if (tempValue[0] === '.') {
    tempValue = '0' + tempValue;
  } else if (isNaN(parseInt(tempValue)) || isNaN(parseFloat(tempValue))) {
    return value;
  }
  if (zeroDigitsOnly) {
    if (withoutComma) {
      return parseInt(tempValue).toFixed(2);
    } else {
      return (
        Number(parseInt(tempValue).toFixed(2)).toLocaleString('en-US') + '.00'
      );
    }
  } else {
    if (withoutComma) {
      return parseFloat(tempValue).toFixed(2);
    } else {
      const temp = Number(parseFloat(tempValue)).toLocaleString('en-US');
      if (!temp.includes('.')) {
        return temp + '.00';
      } else {
        if (temp.lastIndexOf('.') === temp.length - 3) {
          return temp;
        } else if (temp.lastIndexOf('.') < temp.length - 3) {
          const noDotTemp = temp.replaceAll('.', '');
          return noDotTemp.slice(0, -2) + '.' + noDotTemp.slice(-2);
        } else {
          return temp + '0'.repeat(temp.lastIndexOf('.') - (temp.length - 3));
        }
      }
    }
  }
};

export const formatToPhoneNumber = (value: string) => {
  if (value.length >= 5 && value[4] !== ' ') {
    return value.slice(0, 4) + ' ' + value.slice(4);
  } else {
    return value;
  }
};

export const isPhoneNumber = (value: string) => {
  const regexWithSpace = /^[0-9]{4} [0-9]{0,4}$/;
  const regexWithoutSpace = /^[0-9]{0,4}$/;
  return regexWithSpace.test(value) || regexWithoutSpace.test(value);
};

export const toChineseNumber = (value: number) => {
  if (value > 999) {
    return value.toString();
  }
  const chinese = {
    1: '一',
    2: '二',
    3: '三',
    4: '四',
    5: '五',
    6: '六',
    7: '七',
    8: '八',
    9: '九',
    0: '零',
  };
  let result: string = '';
  const hundred = Math.floor(value / 100);
  const ten = Math.floor((value - hundred * 100) / 10);
  const digit = Math.floor(value - (hundred * 100 + ten * 10));
  if (hundred > 0) {
    result += chinese[hundred] + '百';
  }
  if (hundred > 0 && ten === 0) {
    result += chinese[0];
  } else if (ten > 0) {
    if (ten === 1) {
      result += '十';
    } else {
      result += chinese[ten] + '十';
    }
  }
  if (!((ten > 0 || hundred > 0) && digit === 0)) {
    result += chinese[digit];
  }

  return result;
};

export const padWithZeros = (number: number, length?: number) => {
  const desiredLength = length ?? 5;
  const numberStr = number + '';
  const paddedNumber = numberStr.padStart(desiredLength, '0');
  return paddedNumber;
};

export const increaseRefNumber = (
  str: string,
  increaseAmount?: number
): string => {
  const parts = str.split('-');
  const refNumber = parseInt(parts[parts.length - 1]);
  if (!refNumber) {
    return str;
  }
  parts.pop();
  if (increaseAmount === undefined) {
    return `${parts.join('-')}-${padWithZeros(refNumber + 1)}`;
  } else {
    return `${parts.join('-')}-${padWithZeros(refNumber + increaseAmount)}`;
  }
};

export const getHighlightedText = (
  text: string,
  highlight: string
): ReactNode => {
  const escapedHighlight = highlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const parts = text.split(new RegExp(`(${escapedHighlight})`, 'gi'));
  return (
    <span>
      {parts.map((part, i) => (
        <span
          key={i}
          style={
            part.toLowerCase() === escapedHighlight.toLowerCase()
              ? { fontWeight: 'bold' }
              : {}
          }
        >
          {part}
        </span>
      ))}
    </span>
  );
};

export const removeWrongRowAfterCorrecting = (
  prev: Record<number, string[]>,
  rowId: number,
  fieldName: 'driverRef' | 'productRef' | 'jobDate'
) => {
  if (prev[rowId]?.length) {
    if (prev[rowId].length === 1 && prev[rowId].includes(fieldName)) {
      const tempPrev = { ...prev };
      delete tempPrev[rowId];
      return tempPrev;
    } else {
      const nonFieldNameArray = prev[rowId]?.filter(
        (wrongField) => wrongField !== fieldName
      );
      return {
        ...prev,
        [rowId]: nonFieldNameArray?.length ? [...nonFieldNameArray] : [],
      };
    }
  } else {
    return {
      ...prev,
    };
  }
};

export const addWrongRow = (
  prev: Record<number, string[]>,
  rowId: number,
  fieldName: 'driverRef' | 'productRef' | 'jobDate'
) => {
  if (prev[rowId] && prev[rowId].length) {
    if (prev[rowId].includes(fieldName)) {
      return { ...prev };
    } else {
      return {
        ...prev,
        [rowId]:
          prev[rowId] && prev[rowId].length
            ? [...prev[rowId], fieldName]
            : [fieldName],
      };
    }
  } else {
    return {
      ...prev,
      [rowId]: [fieldName],
    };
  }
};

export const formatPrice = (price?: number | null) => {
  if (!price && price !== 0) {
    return '$ -';
  }
  return price.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};

type ArrowDirection =
  | 'ArrowDown'
  | 'ArrowUp'
  | 'ArrowLeft'
  | 'ArrowRight'
  | 'tab'
  | 'current'
  | string;

export const handleInputFocus = (newInputId: string) => {
  if (newInputId) {
    const nextRowElement = document.getElementById(newInputId);
    if (nextRowElement) {
      nextRowElement.focus();
    }
  }
};

export const getNewCoordinates = (
  direction: ArrowDirection,
  formCoordinates: FormCoordinate
) => {
  let tempFormCoordinates: FormCoordinate = {
    ...formCoordinates,
  };
  if (direction === 'ArrowUp') {
    if (!tempFormCoordinates.rowId) {
      return tempFormCoordinates;
    }
    tempFormCoordinates = {
      ...tempFormCoordinates,
      rowId: tempFormCoordinates.rowId - 1,
    };
    return { ...tempFormCoordinates };
  } else if (direction === 'ArrowDown') {
    if (typeof tempFormCoordinates.rowId !== 'number') {
      return tempFormCoordinates;
    }
    tempFormCoordinates = {
      ...tempFormCoordinates,
      rowId: tempFormCoordinates.rowId + 1,
    };
    return { ...tempFormCoordinates };
  } else if (direction === 'ArrowLeft') {
    if (!tempFormCoordinates.colId) {
      return tempFormCoordinates;
    }
    tempFormCoordinates = {
      ...tempFormCoordinates,
      colId: tempFormCoordinates.colId - 1,
    };
    return { ...tempFormCoordinates };
  } else if (direction === 'ArrowRight') {
    if (typeof tempFormCoordinates.colId !== 'number') {
      return tempFormCoordinates;
    }
    tempFormCoordinates = {
      ...tempFormCoordinates,
      colId: tempFormCoordinates.colId + 1,
    };
    return { ...tempFormCoordinates };
  } else if (direction === 'current') {
    return tempFormCoordinates;
  }
  return tempFormCoordinates;
};

export const getNewInputId = (
  direction: ArrowDirection,
  rowId?: number,
  colId?: number,
  inputIdPrefix?: string
) => {
  let inputId = '';
  if ((rowId || rowId === 0) && (colId || colId === 0)) {
    const newCoordinates = getNewCoordinates(direction, { rowId, colId });
    if (
      typeof newCoordinates.rowId === 'number' &&
      typeof newCoordinates.colId === 'number'
    ) {
      inputId = `${inputIdPrefix}-${newCoordinates.rowId}-${newCoordinates.colId}`;
    }
  }
  return inputId;
};

export const handleCoordinatesChange = (
  direction: ArrowDirection,
  rowId?: number,
  colId?: number,
  inputIdPrefix?: string
) => {
  if (direction === 'Tab') {
    return;
  }
  handleInputFocus(getNewInputId(direction, rowId, colId, inputIdPrefix));
};
