import moment from 'moment';

import { formatCustomerAddress } from 'app/helpers';
import {
  Customer,
  CustomerPaymentTerm,
  FormattedInvoice,
  FormInvoiceData,
  FormInvoiceItemData,
  Invoice,
  PriceTable,
} from 'app/models';

import { formatNumber } from './Common';

export const formatInvoiceListData = (invoice: Invoice): FormattedInvoice => {
  return {
    ...invoice,
    address:
      formatCustomerAddress(
        invoice.district,
        invoice.street,
        invoice.building
      ) || '',
  };
};

export const formatInvoiceFormData = (
  data: Invoice,
  priceTables: PriceTable[],
  customerData?: Customer,
  isCopy?: boolean
): FormInvoiceData => {
  const validMainContactFromCustomer = customerData?.Contacts?.find(
    (contact) => contact.isMainContact
  );

  const form: FormInvoiceData = {
    invoiceRef: data.invoiceRef,
    fromCompany: customerData?.fromCompany || data.fromCompany,
    category: data.category,
    documentDate: moment(data.documentDate, 'YYYY-MM-DD').toDate(),
    dueDate: moment(data.dueDate, 'YYYY-MM-DD').toDate(),
    paymentDate: data.paymentDate
      ? moment(data.paymentDate, 'YYYY-MM-DD').toDate()
      : null,
    remarks: customerData?.remarks || data.remarks,
    remarks1:
      customerData?.remarks1 ||
      customerData?.remarks?.split('\n')?.[0] ||
      data.remarks1 ||
      data.remarks?.split('\n')?.[0] ||
      '',
    remarks2:
      customerData?.remarks2 ||
      customerData?.remarks?.split('\n')?.[0] ||
      data.remarks2 ||
      data.remarks?.split('\n')?.[1] ||
      '',
    status: data.status,
    reason: data.reason,
    customerId: customerData?.customerId || data.customerId,
    customerRef: customerData?.customerRef || data.customerRef,
    customerName: customerData?.customerName || data.customerName,
    projectName: customerData?.projectName || data.projectName,
    city: customerData?.city || data.city,
    district: customerData?.district || data.district,
    street: customerData?.street || data.street,
    building: customerData?.building || data.building,
    contactId:
      validMainContactFromCustomer && validMainContactFromCustomer.contactId > 0
        ? validMainContactFromCustomer?.contactId
        : data.contactId,
    contactName: validMainContactFromCustomer?.name || data.contactName,
    contactDepartment:
      validMainContactFromCustomer?.department || data.contactDepartment,
    contactPhone: validMainContactFromCustomer?.phone || data.contactPhone,
    contactMobile: validMainContactFromCustomer?.mobile || data.contactMobile,
    contactFax: validMainContactFromCustomer?.fax || data.contactFax,
    contactEmail: validMainContactFromCustomer?.email || data.contactEmail,
    billingAgreement: customerData?.billingAgreement || data.billingAgreement,
    InvoiceItems: data.InvoiceItems
      ? data.InvoiceItems.map((item) => {
          const tempItem: FormInvoiceItemData = {
            itemOrder: item.itemOrder,
            jobDate: isCopy
              ? null
              : item.jobDate
              ? moment(item.jobDate, 'YYYY-MM-DD').format('YYYY/MM/DD')
              : null,
            driverId: isCopy ? null : item.driverId,
            driverRef: isCopy ? null : item.driverRef,
            workChit: isCopy ? null : item.workChit,
            productId: item.productId,
            productRef: item.productRef,
            itemName: item.itemName,
            quantity: formatNumber(item.quantity || '', true) || null,
            unit: item.unit,
            unitPrice: formatNumber(item.unitPrice || '') || null,
            totalPrice: formatNumber(item.totalPrice || '') || null,
            isDescription: !item.productRef?.startsWith('*') ? false : true,
            priceTableItemId: item.priceTableItemId || null,
            invoiceItemId: isCopy ? undefined : item.invoiceItemId,
          };
          if (!item.priceTableItemId || item.jobDate) {
            return tempItem;
          }
          if (
            !priceTables.some(
              (priceTable) => priceTable.priceTableId === item.priceTableItemId
            )
          ) {
            return {
              ...tempItem,
              priceTableItemId: undefined,
            };
          }
          return tempItem;
        }).sort((a, b) => {
          if (typeof b.itemOrder !== 'number') {
            return 0;
          } else if (typeof a.itemOrder !== 'number') {
            return 1;
          }
          return a.itemOrder - b.itemOrder;
        })
      : [],
    //these will be init in the form page
    preview: false,
    chineseName: '',
    englishName: '',
    projectChineseName: '',
    projectEnglishName: '',
  };
  return form;
};

export const isValidInvoiceRef = (refStr: string): boolean => {
  const regex = /^(PES|SB)-I-\d{0,5}$/;
  const match = regex.exec(refStr);
  return match !== null;
};

export const getDueDate = (
  initialDate: string | Date,
  paymentTerm: CustomerPaymentTerm | null,
  paymentTermValue: number | null
) => {
  if (!paymentTerm) {
    return initialDate;
  } else {
    switch (paymentTerm) {
      case CustomerPaymentTerm.MONTHS_NUMBER:
        return paymentTermValue
          ? moment(initialDate)
              .add(paymentTermValue, 'months')
              .format('YYYY/MM/DD')
          : initialDate;
      case CustomerPaymentTerm.DAYS_NUMBER:
        return paymentTermValue
          ? moment(initialDate)
              .add(paymentTermValue, 'days')
              .format('YYYY/MM/DD')
          : initialDate;
      case CustomerPaymentTerm.MONTH_END_WITH_MONTHS_NUMBER:
        return paymentTermValue
          ? moment(initialDate)
              .add(paymentTermValue, 'months')
              .endOf('month')
              .format('YYYY/MM/DD')
          : moment(initialDate).endOf('month').format('YYYY/MM/DD');
      case CustomerPaymentTerm.MONTH_END_WITH_DAYS_NUMBER:
        return paymentTermValue
          ? moment(initialDate)
              .endOf('month')
              .add(paymentTermValue, 'days')
              .format('YYYY/MM/DD')
          : moment(initialDate).endOf('month').format('YYYY/MM/DD');
    }
  }
};
