import { TimeStamp } from './Common';
import { Customer, CustomerContact } from './Customer';
import { Product } from './Product';
import { EditingUser } from './User';

export interface QuotationItemData {
  quotationItemId?: number;
  itemOrder: number;
  itemName: string | null;
  quantity: string | null;
  unit: string | null;
  unitPrice: string | null;
  totalPrice: string | null;
  productId: number | null;
  productRef: string | null;
  type?: string;
  Product?: Product;
}

export interface QuotationFootnoteItemData {
  content: string;
  quotationId?: number | string;
  quotationFootnoteItemId?: number;
  itemOrder: number;
}
export interface QuotationItem extends QuotationItemData, TimeStamp {
  quotationItemId: number;
}

export interface QuotationFootnoteItem
  extends QuotationFootnoteItemData,
    TimeStamp {
  quotationFootnoteItemId: number;
}

export type QuotationStatus = '草稿' | '已發佈' | '已拒絕' | '已接受';

export enum QuotationStatusOption {
  草稿 = '草稿',
  已發佈 = '已發佈',
  已拒絕 = '已拒絕',
  已接受 = '已接受',
}

export interface QuotationData {
  quotationRef: string;
  fromCompany: string;
  date: string | Date | null;
  remarks: string | null;
  status: QuotationStatus;
  reason: string | null;
  customerId: number | null;
  customerRef: string;
  customerName: string | null;
  projectName: string | null;
  district: string | null;
  street: string | null;
  city: string | null;
  building: string | null;
  contactId: number | null;
  contactName: string;
  contactDepartment: string | null;
  contactPhone: string | null;
  contactMobile: string | null;
  contactFax: string | null;
  contactEmail: string | null;
  QuotationItems: QuotationItemData[];
  quotationFootnoteId: number | null;
  QuotationFootnoteItems: QuotationFootnoteItemData[];
  salesman?: string | null;
}
export interface Quotation extends QuotationData, TimeStamp {
  quotationId: number;
  userId: number | null;
  //remember to rm the ? when integrating real data
  Customer?: Customer;
  Contact?: CustomerContact;
  QuotationItems: QuotationItem[];
  QuotationFootnoteItems: QuotationFootnoteItem[];
  User: EditingUser;
  CreatedByUser: EditingUser | null;
}

export interface FormQuotationItemData
  extends Omit<QuotationItemData, 'itemOrder' | 'quotationItemId'> {
  itemOrder?: number | null;
  isDescription?: boolean;
}

export interface FormQuotationData
  extends Omit<QuotationData, 'QuotationItems'> {
  QuotationItems: FormQuotationItemData[];
  preview: boolean;
  englishName: string;
  chineseName: string;
  projectChineseName: string;
  projectEnglishName: string;
}
